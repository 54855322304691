import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import NewsCard from "../components/NewsCard/NewsCard"
import Section from "../components/Section/Section"
import Seo from "../components/Seo/Seo"
import "../styles/news.scss"
import NewsLinkedIn from "../svg/ico_linkedBlue.svg"
import NewsTwitter from "../svg/ico_twitterBlue.svg"

interface NewsProps {
  data: any
}

const News = ({ data }: NewsProps) => {
  const posts = data.allMarkdownRemark.nodes
  const [isAllNews, setIsAllNews] = useState(true)
  const [isPress, setIsPress] = useState(false)
  const [isArticle, setIsArticle] = useState(false)
  /* HANDLE TOGGLE TRIPPLES */

  const handleAllNews = () => {
    setIsAllNews(true)
    setIsPress(false)
    setIsArticle(false)
  }
  const handlePress = () => {
    setIsPress(true)
    setIsAllNews(false)
    setIsArticle(false)
  }
  const handleArticle = () => {
    setIsArticle(true)
    setIsPress(false)
    setIsAllNews(false)
  }

  let visibilityArticle = ""
  let visibilityPress = ""

  if (isArticle) {
    visibilityArticle = ""
    visibilityPress = "hidden"
  }

  if (isPress) {
    visibilityArticle = "hidden"
    visibilityPress = ""
  }

  if (isAllNews) {
    visibilityArticle = ""
    visibilityPress = ""
  }

  return (
    <Layout hideForm>
      <Seo title="News | Resistant.AI" />

      <Section sectionClass="News">
        <div className="w-wrap">
          <div className="w1-2">
            <div className="MainSection__caption">News</div>
          </div>

          <div className="w1-2 News__header">
            <div className="News__header__left">
              <p>Press Inquiries</p>
              <a href="mailto:marketing@resistant.ai">marketing@resistant.ai</a>
            </div>

            <div className="News__header__right">
              <p>Follow us</p>
              <div className="News__socials">
                <img src={NewsLinkedIn} />
                <img src={NewsTwitter} />
              </div>
            </div>
          </div>
        </div>

        <div className="News__filter w-wrap">
          <div className="w1-2 News__filter__wrapper">
            <p
              onClick={handleArticle}
              className={`News__filter-btn ${
                isArticle ? "News__filter-active" : ""
              }`}
            >
              Articles
            </p>
            <p
              onClick={handlePress}
              className={`News__filter-btn ${
                isPress ? "News__filter-active" : ""
              }`}
            >
              Press Releases
            </p>
            <p
              onClick={handleAllNews}
              className={`News__filter-btn ${
                isAllNews ? "News__filter-active" : ""
              }`}
            >
              All
            </p>
          </div>
        </div>

        {/* NEWS ROW */}

        <div className="w-wrap flexWrap News__body">
          {posts.map((post: any, idx: number) => {
            const postVisibility =
              post.frontmatter.type == "post"
                ? visibilityPress
                : visibilityArticle
            return (
              <React.Fragment key={idx}>
                <NewsCard
                  newsDate={post.frontmatter.date}
                  newsSource={post.frontmatter.newsSource}
                  newsName={post.frontmatter.title}
                  newsLink={post.frontmatter.link}
                  type={post.frontmatter.type}
                  customTextLink={post.frontmatter.customTextLink}
                  newsLinkedIn="https://www.idnes.cz"
                  newsTwitter="https://www.idnes.cz"
                  newsCat="Articles"
                  newsVisibility={postVisibility}
                ></NewsCard>
              </React.Fragment>
            )
          })}
        </div>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          type
          newsSource
          link
          customTextLink
        }
      }
    }
  }
`

export default News
